import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './login.css';
import logo from '../assets/login-logo.png';
import googleIcon from '../assets/google-icon.png';
import phoneIcon from '../assets/phone-icon.png';
import phoneOtpImage from '../assets/phone-otp.png';
import GoogleOtpImage from '../assets/mail-otp.png';
import countryCodes from '../data/countryCodes.json';

const Login = () => {
  // State definitions
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [userType, setUserType] = useState('personal');
  const [isRegister, setIsRegister] = useState(false);
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isGoogleOtpModalOpen, setIsGoogleOtpModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [forgotPasswordStep, setForgotPasswordStep] = useState(1); // Step 1: Email, Step 2: OTP, Step 3: Reset Password
  const [isLoading, setIsLoading] = useState(false); // Loading state for login and signup
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.redirectTo || '/';

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const openPhoneNumberModal = () => setIsPhoneNumberModalOpen(true);
  const closePhoneNumberModal = () => setIsPhoneNumberModalOpen(false);

  const openOtpModal = () => {
    closePhoneNumberModal();
    setIsOtpModalOpen(true);
  };
  const closeOtpModal = () => setIsOtpModalOpen(false);

  const openGoogleOtpModal = () => setIsGoogleOtpModalOpen(true);
  const closeGoogleOtpModal = () => setIsGoogleOtpModalOpen(false);

  const openForgotPasswordModal = () => setIsForgotPasswordModalOpen(true);
  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
    setForgotPasswordStep(1); // Reset to first step when closing
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading for forgot password

    if (forgotPasswordStep === 1) {
      // Step 1: Send OTP to email
      try {
        const response = await fetch('https://linkfreightapi.vercel.app/api/forgot_psw', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: forgotPasswordEmail }),
        });

        if (response.ok) {
          const result = await response.json();
          alert(result.message); // Notify user that OTP was sent
          setForgotPasswordStep(2); // Proceed to the OTP entry step
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
        alert('An error occurred while sending OTP. Please try again.');
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else if (forgotPasswordStep === 2) {
      // Step 2: Verify OTP
      try {
        const response = await fetch('https://linkfreightapi.vercel.app/api/forgot_psw', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: forgotPasswordEmail, otp: otp.join('') }),
        });

        if (response.ok) {
          setForgotPasswordStep(3); // Proceed to reset password step
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        alert('An error occurred while verifying OTP. Please try again.');
      } finally {
        setIsLoading(false); // Stop loading
      }
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert('Passwords do not match.');
      return;
    }

    setIsLoading(true); // Start loading for reset password

    // Step 3: Reset Password
    try {
      const response = await fetch('https://linkfreightapi.vercel.app/api/forgot_psw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: forgotPasswordEmail,
          otp: otp.join(''),
          newPassword,
          confirmPassword: confirmNewPassword,
        }),
      });

      if (response.ok) {
        alert('Password reset successful!');
        setIsForgotPasswordModalOpen(false);
        setForgotPasswordStep(1); // Reset to first step after completion
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('An error occurred while resetting the password. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handlePhoneNumberSubmit = (e) => {
    e.preventDefault();
    openOtpModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Start loading for login or signup

    // Client-side validation
    if (isRegister) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^\d{10}$/;  // Adjust to match the required length of phone number

      if (!fullName || !phoneNumber || !email || !password || !confirmPassword) {
        alert('All fields are required.');
        setIsLoading(false); // Stop loading
        return;
      }

      // Email validation
      if (!emailPattern.test(email)) {
        alert('Please enter a valid email address.');
        setIsLoading(false); // Stop loading
        return;
      }

      // Phone number validation
      if (!phonePattern.test(phoneNumber)) {
        alert('Please enter a valid 10-digit phone number.');
        setIsLoading(false); // Stop loading
        return;
      }

      if (password !== confirmPassword) {
        alert('Passwords do not match');
        setIsLoading(false); // Stop loading
        return;
      }
    } else {
      if (!email || !password) {
        alert('All fields are required.');
        setIsLoading(false); // Stop loading
        return;
      }
    }

    try {
      if (isRegister) {
        // Post signup data to the API
        const response = await fetch('https://linkfreightapi.vercel.app/api/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fullname: fullName,
            phone: phoneNumber,
            email: email,
            password: password,
            user_type: userType,
          }),
        });

        if (response.ok) {
          const user = await response.json();
          console.log('User registered:', user);
         // alert('Signup successful!');
          window.location.reload();  // Reload the page after successful signup
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.error}`);
        }
      } else {
        // Post login data to the API
        const response = await fetch('https://linkfreightapi.vercel.app/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });

        if (response.ok) {
          const user = await response.json();
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('userEmail', email); // Store the email in local storage
          //alert('Login successful!');
          navigate(redirectTo);
          window.location.reload();
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.error}`);
        }
      }
    } catch (error) {
      console.error(`Error ${isRegister ? 'registering' : 'logging in'} user`, error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className='container'>
      <div className='logo-section'>
        <img src={logo} className='login-logo' alt="logo" />
        <h2 className='title'>LINK<span className='span-title'>FREIGHT</span></h2>
      </div>
      <div className="login-container">
        <h2 className='title'>{isRegister ? 'SignUp' : 'Login'}</h2>
        <form onSubmit={handleSubmit}>
          {isRegister && (
            <>
              <div className="inputBox">
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
                <label>Full Name</label>
              </div>
              <br />
            </>
          )}
          <div className="inputBox">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label>Email</label>
          </div>
          <br />
          {isRegister && (
            <>
              <div className="inputBox">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                <label>Phone Number</label>
              </div>
              <br />
            </>
          )}
          <div className="inputBox">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label>Password</label>
          </div>
          <br />
          {isRegister && (
            <>
              <div className="inputBox">
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <label>Confirm Password</label>
              </div>
              <br />
            </>
          )}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLoading ? 'Loading...' : isRegister ? 'Sign Up' : 'Login'}
          </button>
        </form>
        <br />
        <div className='forgot-password'>
          {!isRegister && (
            <a href="#" className='forgot-password-link' onClick={openForgotPasswordModal}>Forgot Password?</a>
          )}
        </div>
        <div className='signup-link'>
          {isRegister ? (
            <p>Already have an account? <a href="#" onClick={() => setIsRegister(false)}>Login</a></p>
          ) : (
            <p>Don't have an account? <a href="#" onClick={() => setIsRegister(true)}>Sign Up</a></p>
          )}
        </div><br /><br />

        {/* Continue with Google and Phone Number Options */}
        <div className="login-options-row" hidden>
          <div className="hiddengoogle-login" onClick={openGoogleOtpModal} hidden>
            <img src={googleIcon} alt="Google Icon" className="login-icon" hidden/>
            <span hidden>Continue with Google</span>
          </div>
          <div className="hiddenphone-login" onClick={openPhoneNumberModal} hidden>
            <img src={phoneIcon} alt="Phone Icon" className="login-icon" hidden/>
            <span hidden>Continue with Phone number</span>
          </div>
        </div><br />
      </div>

      {/* Forgot Password Modal */}
      {isForgotPasswordModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={closeForgotPasswordModal}>X</button><br />
            {forgotPasswordStep === 1 && (
              <>
                <img src={GoogleOtpImage} alt="Google OTP" className="otp-image" />
                <h3>Forgot Password</h3><br />
                <form onSubmit={handleForgotPasswordSubmit}>
                  <div className="inputBox">
                    <input
                      type="email"
                      value={forgotPasswordEmail}
                      onChange={(e) => setForgotPasswordEmail(e.target.value)}
                      required
                    />
                    <label>Email</label>
                  </div>
                  <br />
                  <button type="submit" className="submit-btn" disabled={isLoading}>
                    {isLoading ? 'Sending OTP...' : 'Send OTP'}
                  </button>
                  <br /><br /><br />
                </form>
              </>
            )}
            {forgotPasswordStep === 2 && (
              <>
                <h3>Enter OTP</h3><br />
                <img src={GoogleOtpImage} alt="Google OTP" className="otp-image" />
                <div className="otp-input-container">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      className="otp-input"
                    />
                  ))}
                </div><br />
                <button className="submit-otp-btn" onClick={handleForgotPasswordSubmit} disabled={isLoading}>
                  {isLoading ? 'Verifying...' : 'Verify OTP'}
                </button>
                <br /><br /><br />
              </>
            )}
            {forgotPasswordStep === 3 && (
              <>
                <img src={GoogleOtpImage} alt="Google OTP" className="otp-image" />
                <h3>Reset Password</h3><br />
                <form onSubmit={handleResetPasswordSubmit}>
                  <div className="inputBox">
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <label>New Password</label>
                  </div>
                  <br />
                  <div className="inputBox">
                    <input
                      type="password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      required
                    />
                    <label>Confirm New Password</label>
                  </div>
                  <br />
                  <button type="submit" className="submit-btn" disabled={isLoading}>
                    {isLoading ? 'Resetting...' : 'Reset Password'}
                  </button>
                  <br /><br /><br />
                </form>
              </>
            )}
          </div>
        </div>
      )}

      {/* Phone Number Modal */}
      {isPhoneNumberModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={closePhoneNumberModal}>X</button><br />
            <img src={phoneOtpImage} alt="Phone OTP" className="otp-image" />
            <h3>Enter Your Phone Number</h3><br />
            <form onSubmit={handlePhoneNumberSubmit}>
              <div className="otp-input-container">
                <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)} required>
                  {countryCodes.map((code) => (
                    <option key={code.dial_code} value={code.dial_code}>
                      {code.dial_code}
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  required
                />
              </div><br />
              <button type="submit" className="submit-otp-btn" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
              <br /><br /><br />
            </form>
          </div>
        </div>
      )}

      {/* Phone OTP Modal */}
      {isOtpModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={closeOtpModal}>X</button><br />
            <img src={phoneOtpImage} alt="Phone OTP" className="otp-image" />
            <h3>Enter the OTP sent to your phone</h3><br /><br />
            <div className="otp-input-container">
              {otp.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  className="otp-input"
                />
              ))}
            </div><br /><br />
            <button className="submit-otp-btn" onClick={closeOtpModal} disabled={isLoading}>
              {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
            </button>
            <br /><br /><br />
          </div>
        </div>
      )}

      {/* Google OTP Modal */}
      {isGoogleOtpModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={closeGoogleOtpModal}>X</button><br />
            <img src={GoogleOtpImage} alt="Google OTP" className="otp-image" />
            <h3>Enter the OTP sent to your Gmail</h3><br /><br />
            <div className="otp-input-container">
              {otp.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  className="otp-input"
                />
              ))}
            </div><br /><br />
            <button className="submit-otp-btn" onClick={closeGoogleOtpModal} disabled={isLoading}>
              {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
            </button>
            <br /><br /><br />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
