import React, { useState, useEffect } from 'react';
import './mybookings.css';

const MyBookings = () => {
  const [user, setUser] = useState(null);
  const [bookingDetails, setBookingDetails] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUser(userData);
      fetchBookingDetails(userData.email); // Pass the email to fetchBookingDetails
    }
  }, []);

  const fetchBookingDetails = async (email) => {
    try {
      const response = await fetch('https://linkfreightapi.vercel.app/api/mybookings', {
        method: 'POST', // Change to POST method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }), // Send email in the request body
      });

      if (!response.ok) {
        throw new Error('Failed to fetch booking details');
      }

      const data = await response.json();

      // Debugging: Log data to ensure it is fetched correctly
      console.log('Fetched booking details:', data);

      setBookingDetails(data);
    } catch (error) {
      console.error('Error fetching booking details:', error);
    }
  };

  return (
    <div className="my-bookings-container">
      <h1>My Bookings</h1>
      {user ? (
        <>
          {bookingDetails.length === 0 ? (
            <p>There are no bookings.</p>
          ) : (
            <>
              <div className="my-bookings-user-info">
                <p>Username: {user.fullname}</p>
                <p>Email: {user.email}</p>
                <p>Phone: {user.phone}</p>
              </div>

              <div className="my-bookings-details">
                <h2>Booking Details</h2>
                <table className="my-bookings-styled-table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Pickup Location</th>
                      <th>Dropoff Location</th>
                      <th>Pickup Date</th>
                      <th>Dropoff Date</th>
                      <th>Goods Description</th>
                      <th>Goods Weight (kg)</th>
                      <th>Goods Value</th>
                      <th>Booking Status</th>
                      {/* <th>Price ($)</th> */}
                      <th>Payment Status</th>
                      <th>Quote Estimation</th>
                      <th>Invoice</th>
                      <th>Proof of Delivery</th>
                      <th>Payment Method</th>
                      <th>Special Instructions</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingDetails.map((booking, index) => (
                      <tr key={booking.id}>
                        <td>{index + 1}</td> {/* Serial Number */}
                        <td>{booking.pickup}</td>
                        <td>{booking.dest}</td>
                        <td>{booking.pickup_date}</td>
                        <td>{booking.dropoff_date}</td>
                        <td>{booking.goods_desc}</td>
                        <td>{booking.good_wt}</td>
                        <td>{booking.goods_value}</td>                   
                        <td>{booking.booking_status}</td>
                        {/* <td>{booking.price}</td> */}
                        <td>{booking.payment_status}</td>
                        <td>
                          {booking.quotation_invoice_upload ? (
                            <button
                              onClick={() => window.open(booking.quotation_invoice_upload, '_blank')}
                              style={{
                                padding: '5px 8px',
                                fontSize: '0.9em',
                                backgroundColor: '#28a745',
                                color: '#fff',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                border: 'none',
                              }}
                            >
                              View
                            </button>
                          ) : (
                            <span>Pending</span>
                          )}
                        </td>
                        <td>
                          {booking.actual_amount_invoice ? (
                            <button
                              onClick={() => window.open(booking.actual_amount_invoice, '_blank')}
                              style={{
                                padding: '5px 8px',
                                fontSize: '0.9em',
                                backgroundColor: '#28a745',
                                color: '#fff',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                border: 'none',
                              }}
                            >
                              View
                            </button>
                          ) : (
                            <span>Pending</span>
                          )}
                        </td>
                        <td>
                          {booking.proof_of_delivery ? (
                            <button
                              onClick={() => window.open(booking.proof_of_delivery, '_blank')}
                              style={{
                                padding: '5px 8px',
                                fontSize: '0.9em',
                                backgroundColor: '#28a745',
                                color: '#fff',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                border: 'none',
                              }}
                            >
                              View
                            </button>
                          ) : (
                            <span>Pending</span>
                          )}
                        </td>
                        <td>{booking.payment_method}</td>
                        <td>{booking.comments}</td>
                        <td>{new Date(booking.created_at).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      ) : (
        <p>Please log in to view your bookings.</p>
      )}
    </div>
  );
};

export default MyBookings;