import React from 'react';
import './OurServices.css';
import Slider from "react-slick";
import localServices from '../assets/local services.webp';
import customTransport from '../assets/express.JPG';
import freightman from '../assets/freight.JPG';
import expressShipping from '../assets/express shipping.webp'



const OurServices = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='carousel'>
            <br /><br /><br /><br />
            <h2 className='heading'>OUR <span className='services'>SERVICES</span></h2>
            <div className='slider-container'>
                <Slider {...settings}>
                    <div>
                        <div className='carousel-container'>
                            <h2>Local Delivery Services</h2>
                            <img src={localServices} className='image' alt="Local Delivery Services"/>
                            <p>Quick and reliable local delivery services for businesses and individuals. Our experienced drivers ensure your goods are delivered on time.</p>
                        </div>
                    </div>
                    <div>
                        <div className='carousel-container'>
                            <h2>Custom Transport Solutions</h2>
                            <img src={customTransport} className='image' alt="Freight Management"/>
                            <p>Tailored transport solutions to meet unique business needs. Whether you need special handling, temperature-controlled transport, or oversized loads, we’ve got you covered.</p>
                        </div>
                    </div>
                    <div>
                        <div className='carousel-container'>
                            <h2>Freight Management</h2>
                            <img src={freightman} className='image' alt="Warehouse Solutions"/>
                            <p>Comprehensive freight management solutions to streamline your logistics and ensure efficient transport of goods.</p>
                        </div>
                    </div>
                    <div>
                        <div className='carousel-container'>
                            <h2>Express Shipping</h2>
                            <img src={expressShipping} className='image' alt="International Shipping"/>
                            <p>Need it there fast? Our express shipping services guarantee expedited delivery for time-sensitive shipments.</p>
                        </div>
                    </div>
                </Slider>
            </div><br />
        </div>
    );
};

export default OurServices;
