import React, { useState, useRef, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './navbar/navbar';
import AboutUs from './about/about';
import OurServices from './OurServices/OurServices';
import Contactus from './contact/contact';
import Footer from './footer/footer';
import Login from './login/login';
import Faq from './faqs/faqs';
import BookNow from './bookNow/booknow';
import EstimatePopup from './estimatePopUp/estimatePopUp';
import TrucksForm from './detailedEstimate/detailedEstimate';
import EstimateOptions from './estimateOptions/estimateOptions';
import GetEnquiry from './getEnquiry/getEnquiry'; 
import MyBookings from './mybookings/mybookings'; // Import MyBookings component


function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dialogRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showBookNow, setShowBookNow] = useState(false);
  const [estimateAmount, setEstimateAmount] = useState('');
  const [showCallDialog, setShowCallDialog] = useState(false);

  const handleBookNowClick = () => {
    setShowBookNow(true);
  };

  const handleCloseBookNow = () => {
    setShowBookNow(false);
  };

  const handleEstimate = (amount) => {
    setEstimateAmount(amount);
    setShowBookNow(false);

    if (isLoggedIn) {
      navigate('/estimate-options');
    } else {
      navigate('/estimate-popup', { state: { estimateAmount: amount } });
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleCallUsClick = () => {
    setShowCallDialog(true);
  };

  const handleCloseDialog = () => {
    setShowCallDialog(false);
  };

  useEffect(() => {
    if (showCallDialog && dialogRef.current) {
      dialogRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showCallDialog]);

  return (
    <div className="App">
      {location.pathname !== '/login' && 
       location.pathname !== '/truck-form' && 
       location.pathname !== '/get-enquiry' && 
       location.pathname !== '/my-bookings' && (
        <>
          <section id="home">
            <Navbar onBookNowClick={handleBookNowClick} isLoggedIn={isLoggedIn} onLogout={handleLogout} />
          </section>
          <section id="about">
            <AboutUs />
          </section>
          <section id="services">
            <OurServices />
          </section>
          <section id="contact">
            <Contactus />
          </section>
          <section id="faq">
            <Faq />
          </section>
          <Footer />
          <a href="#" className="call-us-button" onClick={handleCallUsClick}>
            <i className="fa-solid fa-phone"></i>
            <p className="para">Call Us</p>
          </a>
        </>
      )}

      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/truck-form" element={<TrucksForm />} />
        <Route path="/estimate-popup" element={<EstimatePopup estimateAmount={estimateAmount} onClose={() => navigate('/')} />} />
        <Route path="/estimate-options" element={<EstimateOptions />} />
        <Route path="/get-enquiry" element={<GetEnquiry />} />
        <Route path="/my-bookings" element={<MyBookings />} />
      </Routes>

      {showBookNow && (
        <BookNow
          onClose={handleCloseBookNow}
          onEstimate={handleEstimate}
          isLoggedIn={isLoggedIn}
        />
      )}

      {showCallDialog && (
        <div className="dialog-overlay" ref={dialogRef}>
          <div className="dialog-box">
            <button className="close-icon" onClick={handleCloseDialog}>
              &times;
            </button>
            <h2>Contact Us</h2>
            <p>Call us at: <a href="tel:+91 7793988939" className="contact-number">+91 7793988939</a></p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
