import React, { useState, useEffect, useRef } from 'react';
import './getEnquiry.css';
import mail_logo from '../assets/email.png';

const EnquiryForm = () => {
  const [pickupLocation, setPickupLocation] = useState('');
  const [destinationLocation, setDestinationLocation] = useState('');
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [alternativeEmail, setAlternativeEmail] = useState(''); // New field
  const [phone, setPhone] = useState('');
  const [comments, setComments] = useState('');
  const [goodsDescription, setGoodsDescription] = useState('');
  const [goodsWeight, setGoodsWeight] = useState('');
  const [goodsValue, setGoodsValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState(1);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [errors, setErrors] = useState({});
  
  const pickupInputRef = useRef(null);
  const destinationInputRef = useRef(null);

   // Initialize Google Autocomplete
   useEffect(() => {
    if (window.google) {
      const pickupAutocomplete = new window.google.maps.places.Autocomplete(pickupInputRef.current);
      const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInputRef.current);

      // Event listeners for place selection
      pickupAutocomplete.addListener('place_changed', () => {
        const place = pickupAutocomplete.getPlace();
        setPickupLocation(place.formatted_address || place.name);
      });

      destinationAutocomplete.addListener('place_changed', () => {
        const place = destinationAutocomplete.getPlace();
        setDestinationLocation(place.formatted_address || place.name);
      });
    }
  }, []);

  const handleUseCurrentLocation = async (setLocation) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD-LRSQCc2gyvN6pumVWe9ZWevyGmub8YY`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const currentAddress = data.results[0]?.formatted_address;
          if (currentAddress) {
            setLocation(currentAddress);
          }
        } catch (error) {
          console.error('Failed to fetch current location:', error);
        }
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const validateSection1 = () => {
    const newErrors = {};

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Phone number validation regex (for 10-digit phone numbers)
    const phonePattern = /^\d{10}$/;

    if (!pickupLocation) newErrors.pickupLocation = 'Pickup address is required.';
    if (!destinationLocation) newErrors.destinationLocation = 'Destination address is required.';
    if (!name) newErrors.name = 'Name is required.';

    // Email validation
    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (!emailPattern.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Alternative Email validation
    if (alternativeEmail && !emailPattern.test(alternativeEmail)) {
      newErrors.alternativeEmail = 'Please enter a valid alternative email address.';
    }

    // Phone number validation
    if (!phone) {
      newErrors.phone = 'Phone number is required.';
    } else if (!phonePattern.test(phone)) {
      newErrors.phone = 'Please enter a valid 10-digit phone number.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const validateSection2 = () => {
    const newErrors = {};
    if (!goodsDescription) newErrors.goodsDescription = 'Goods description is required.';
    if (!goodsWeight) newErrors.goodsWeight = 'Goods weight is required.';
    if (!goodsValue) newErrors.goodsValue = 'Goods value is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateSection2()) return;

    setIsLoading(true);

    const formattedGoodsWeight = parseFloat(goodsWeight);
    const formattedGoodsValue = parseInt(goodsValue, 10);

    try {
      const response = await fetch('https://linkfreightapi.vercel.app/api/enquiries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify({
          usertype: null,
          name,
          email,
          alt_email: alternativeEmail, // Include alternative email
          phone,
          pickup: pickupLocation,
          dest: destinationLocation,
          comments,
          goods_desc: goodsDescription,
          good_wt: parseFloat(goodsWeight),
          goods_value: parseInt(goodsValue, 10),
          booking_status: null,
          price: null,
          payment_status: "Unpaid",
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit enquiry');
      }

      // Create a link element to trigger download
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'enquiry-details.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();

      setIsPopupVisible(true);
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      alert('Failed to submit enquiry. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    resetForm();
  };

  const resetForm = () => {
    setPickupLocation('');
    setDestinationLocation('');
    setPickupSuggestions([]);
    setDestinationSuggestions([]);
    setName('');
    setEmail('');
    setAlternativeEmail('');
    setPhone('');
    setComments('');
    setGoodsDescription('');
    setGoodsWeight('');
    setGoodsValue('');
    setCurrentSection(1);
    setErrors({});
  };

  const handleNext = () => {
    if (validateSection1()) {
      setCurrentSection(2);
    }
  };

  return (
    <div className="enquiry-page">
      <div className="enquiry-form-container">
        <div className="enquiry-form-section">
          <h2 className="enquiry-form-title">Please provide your contact information:</h2>
          <p className="enquiry-form-subtitle">Our team will reach out to you for a demo.</p>
          <form onSubmit={handleSubmit}>
            {currentSection === 1 && (
              <>
                <div className="enquiry-form-group">
                  <label htmlFor="pickup">Pickup Address</label>
                  <div className="location-input-container">
                    <input
                      ref={pickupInputRef}
                      type="text"
                      id="pickup"
                      placeholder="Search location..."
                      value={pickupLocation}
                      onChange={(e) => setPickupLocation(e.target.value)}
                      required
                    />
                    {errors.pickupLocation && <span className="error-message">{errors.pickupLocation}</span>}
                    <button
                      type="button"
                      className="use-location-btn"
                      onClick={() => handleUseCurrentLocation(setPickupLocation)}
                    >
                      <i id="loc" className="fa-solid fa-location-dot"></i>
                    </button>
                  </div>
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="destination">Destination Address</label>
                  <div className="location-input-container">
                    <input
                      ref={destinationInputRef}
                      type="text"
                      id="destination"
                      placeholder="Search location..."
                      value={destinationLocation}
                      onChange={(e) => setDestinationLocation(e.target.value)}
                      required
                    />
                    {errors.destinationLocation && <span className="error-message">{errors.destinationLocation}</span>}
                    <button
                      type="button"
                      className="use-location-btn"
                      onClick={() => handleUseCurrentLocation(setDestinationLocation)}
                    >
                      <i id="loc" className="fa-solid fa-location-dot"></i>
                    </button>
                  </div>
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  {errors.name && <span className="error-message">{errors.name}</span>}
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="email">Email Address</label>
                  <div className="enquiry-input-icon">
                    <i className="fas fa-envelope"></i>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && <span className="error-message">{errors.email}</span>}
                  </div>
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="alt_email">Alternative Email Address</label>
                  <input
                    type="email"
                    id="alt_email"
                    placeholder="Enter alternative email (optional)"
                    value={alternativeEmail}
                    onChange={(e) => setAlternativeEmail(e.target.value)}
                  />
                  {errors.alternativeEmail && <span className="error-message">{errors.alternativeEmail}</span>}
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    placeholder="Enter your phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  {errors.phone && <span className="error-message">{errors.phone}</span>}
                </div>
                <br /><br />
                <button type="button" className="enquiry-submit-btn" onClick={handleNext}>
                  Next
                </button>
              </>
            )}

            {currentSection === 2 && (
              <>
                <div className="enquiry-form-group">
                  <label htmlFor="goodsDescription">Goods Description</label>
                  <input
                    type="text"
                    id="goodsDescription"
                    placeholder="Enter goods description"
                    value={goodsDescription}
                    onChange={(e) => setGoodsDescription(e.target.value)}
                    required
                  />
                  {errors.goodsDescription && <span className="error-message">{errors.goodsDescription}</span>}
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="goodsWeight">Goods Weight (kg)</label>
                  <input
                    type="number"
                    id="goodsWeight"
                    placeholder="Enter goods weight"
                    value={goodsWeight}
                    onChange={(e) => setGoodsWeight(e.target.value)}
                    min="1"
                    required
                  />
                  {errors.goodsWeight && <span className="error-message">{errors.goodsWeight}</span>}
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="goodsValue">Goods Value</label>
                  <input
                    type="number"
                    id="goodsValue"
                    placeholder="Enter goods value"
                    value={goodsValue}
                    onChange={(e) => setGoodsValue(e.target.value)}
                    min="1"
                    required
                  />
                  {errors.goodsValue && <span className="error-message">{errors.goodsValue}</span>}
                </div>
                <div className="enquiry-form-group">
                  <label htmlFor="comments">Comments</label>
                  <textarea
                    id="comments"
                    placeholder="Enter your comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div><br /><br />
                <div className="enquiry-form-buttons">
                  <button type="button" className="enquiry-submit-btn" onClick={() => setCurrentSection(1)}>
                    Previous
                  </button>
                  <button type="submit" className="enquiry-submit-btn" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit! '}
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
        <div className="enquiry-image-section">
          <img src={mail_logo} alt="Illustration" className="enquiry-illustration-image" />
        </div>
      </div>

      {/* Popup Modal */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup-btn" onClick={closePopup}>X</button>
            <svg className="popup-checkmark" viewBox="0 0 52 52">
              <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="checkmark-check" fill="none" d="M14 27l10 10 15-15"/>
            </svg>
            <h3>Thanks for the enquiry, we will get back to you very soon!</h3><br /><br />

            {/* New Pickup and Drop Containers */}
            <div className="address-container" style={{ animationDelay: '0.5s' }}>
              <div className="pickup-container">
                <strong>Pickup Address:</strong>
                <p>{pickupLocation}</p>
              </div>
              <div className="drop-container">
                <strong>Destination Address:</strong>
                <p>{destinationLocation}</p>
              </div>
            </div>

            {/* Split Table Layout */}
            <div className="popup-table-container">
              <div className="popup-table-left">
                <table className="popup-form-data-table">
                  <tbody>
                    <tr><td><strong>Name:</strong></td><td>{name}</td></tr>
                    <tr><td><strong>Email:</strong></td><td>{email}</td></tr>
                    <tr><td><strong>Alternative Email:</strong></td><td>{alternativeEmail}</td></tr> 
                    <tr><td><strong>Phone:</strong></td><td>{phone}</td></tr>
                  </tbody>
                </table>
              </div>
              <div className="popup-table-right">
                <table className="popup-form-data-table">
                  <tbody>
                    <tr><td><strong>Goods Description:</strong></td><td>{goodsDescription}</td></tr>
                    <tr><td><strong>Goods Weight:</strong></td><td>{goodsWeight} kg</td></tr>
                    <tr><td><strong>Goods Value:</strong></td><td>₹{goodsValue}</td></tr>
                  </tbody>
                </table>
              </div>
            </div><br /><br />

            {/* Centered Comments Section */}
            <div className="popup-comments-container">
              <strong>Comments:</strong><br />
              <br />
              <p>{comments}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnquiryForm;
