import React from "react";
import logo from '../assets/logo.png';
import './footer.css';

const Footer = () => {
    return (
        <div className="footer-container">
            <br />
            <div className="footer-content">
                <div className="footer-left">
                    <div className="footer-brand">
                        <a href="#"><img src={logo} className='logo' alt="LinkFreight Logo" /></a>
                        <p>LINKFREIGHT</p>
                    </div>
                    <h2 className="headingFooter">Connect with us</h2>
                    <ul className='sci'>
                        <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-linkedin"></i></a></li>
                    </ul>
                </div>
                <div className="footer-links">
                    <ul>
                        <li><a href="#home">HOME</a></li>
                        <li><a href="#about">ABOUT</a></li>
                        <li><a href="#services">SERVICES</a></li>
                        <li><a href="#contact">CONTACT</a></li>
                        {/* <li><a href="#Track">TRACK SHIPMENT</a></li> */}
                        <li><a href="#faq">CUSTOMER FAQ</a></li>

                    </ul>
                </div>
            </div>
            <hr className="line"/>
            <div className="footer-copyright">
                <p>&copy; 2024 LINKFREIGHT. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer;