import React, { useEffect } from 'react';
import './about.css';
import missionImage from '../assets/mission.webp';

const AboutUs = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1, 
      }
    );

    const sections = document.querySelectorAll('.section');
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="about-section"><br /><br />
      <h2 className='title'>LINK<span className='span-title'>FREIGHT</span></h2>
      <p className='paragraph'> At Link Freight, we deliver top-notch transportation and courier services with utmost care and efficiency, ensuring excellence and reliability for all your needs.</p>

      <div className="mission-vision-values">
        <div className="section">
          <div className='card-text'>
            <h3 className='card-heading'>Our Mission</h3>
            <p className='card-paragraph'>Our mission is to be your trusted transportation partner, offering fast, reliable, and cost-effective solutions to ensure your packages reach their destination safely and on time.</p>
          </div>
          <div className='card-image-container'>
            <img src={missionImage} alt="mission" className='card-image' />
          </div>
        </div>
      </div><br /><br /><br /><br />
    </div>
  );
}

export default AboutUs;
