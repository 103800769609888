import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './booknow.css';

const BookNow = ({ onClose, onEstimate, isLoggedIn }) => {
  const [pickup, setPickup] = useState('');
  const [destination, setDestination] = useState('');
  const [estimateAmount, setEstimateAmount] = useState('');
  const [pickupError, setPickupError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  // Refs for Google Places Autocomplete inputs
  const pickupInputRef = useRef(null);
  const destinationInputRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      console.log("Initializing Autocomplete in BookNow");
      console.log("pickupInputRef:", pickupInputRef.current);
      console.log("destinationInputRef:", destinationInputRef.current);
  
      const pickupAutocomplete = new window.google.maps.places.Autocomplete(pickupInputRef.current);
      const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInputRef.current);
  
      pickupAutocomplete.addListener('place_changed', () => {
        const place = pickupAutocomplete.getPlace();
        console.log("Pickup place changed:", place);
        setPickup(place.formatted_address || place.name);
        setPickupError(false);
      });
  
      destinationAutocomplete.addListener('place_changed', () => {
        const place = destinationAutocomplete.getPlace();
        console.log("Destination place changed:", place);
        setDestination(place.formatted_address || place.name);
        setDestinationError(false);
      });
    } else {
      console.error("Google Maps JavaScript API not loaded.");
    }
  }, []);
   // Empty dependency array to ensure this runs once when component mounts

  const handleEstimateClick = () => {
    // Reset error states
    setPickupError(false);
    setDestinationError(false);

    // Check if the fields are empty
    if (!pickup) setPickupError(true);
    if (!destination) setDestinationError(true);

    // If any field is empty, prevent submission
    if (!pickup || !destination) {
      return;
    }

    const estimateAmount = "Estimated Amount: $50"; 
    setEstimateAmount(estimateAmount);
    onEstimate(estimateAmount);

    if (isLoggedIn) {
      navigate('/truck-form');
    } else {
      navigate('/estimate-popup', { state: { estimateAmount } });
    }
  };

  const handlePickupChange = (e) => {
    setPickup(e.target.value);
    if (e.target.value) setPickupError(false);
  };

  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
    if (e.target.value) setDestinationError(false);
  };

  return (
    <div className="booknow-container">
      <h2 className="title">Book Now</h2>
      <div className="form-group-horizontal">
        <div className="form-group">
          <div className="input-container">
            <input
              ref={pickupInputRef}
              type="text"
              id="pickup-location"
              className="form-control"
              placeholder="Enter pickup location"
              value={pickup}
              onChange={handlePickupChange}
              required
              autoComplete="off"
            />
            <i className="location-icon fa fa-location-arrow"></i>
          </div>
          {pickupError && <p className="error-message">This field is required</p>}
        </div>
        <div className="form-group">
          <div className="input-container">
            <input
              ref={destinationInputRef}
              type="text"
              id="destination-location"
              className="form-control"
              placeholder="Enter destination location"
              value={destination}
              onChange={handleDestinationChange}
              required
              autoComplete="off"
            />
            <i className="location-icon fa fa-location-arrow"></i>
          </div>
          {destinationError && <p className="error-message">This field is required</p>}
        </div>
      </div>
      <button className="estimate-button" onClick={handleEstimateClick}>Get Estimate</button>
      <button className="close-button" onClick={onClose}>X</button>
    </div>
  );
};

export default BookNow;
