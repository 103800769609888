import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './estimatePopUp.css';

const EstimatePopup = ({ onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();

  

  const handleLoginClick = () => {
    onClose();
    navigate('/login', { state: { redirectTo: '/trucks-form' } }); // Passing where to redirect after login
  };

  return (
    <div className='modal'>
      <div className='modal-content'>
        <span className='close' onClick={onClose}>&times;</span>
        <div className='big-card'>
          <div className='estimate-container'>
            <p>Please sign up or log in to know more about us.</p>
            <button className='login-button' onClick={handleLoginClick}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimatePopup;
