import React from 'react';
import { useNavigate } from 'react-router-dom';
import './estimateOptions.css';

const EstimateOptions = ({ onClose }) => {
  const navigate = useNavigate();

  const options = [
    { label: 'Mini Trucks', icon: '🚚', link: '/truck-form' },
    { label: 'Trucks', icon: '🚚', link: '/truck-form' },
    { label: 'Heavy Trucks', icon: '🚚', link: '/truck-form' },
  ];

  return (
    <div className="estimate-options-container">
      <div className="estimate-options-left">
        <h2>Get an Estimate</h2>
        <p>Please fill in the details, so we can provide you with the best of our services</p>
      </div>
      <div className="estimate-options-right">
        {options.map((option, index) => (
          <div
            key={index}
            className="option-card"
            onClick={() => navigate(option.link)}
          >
            <span className="icon">{option.icon}</span>
            <span className="label">{option.label}</span>
            <span className="arrow">➔</span>
          </div>
        ))}
      </div>
      <button className="close-button" onClick={onClose}>✕</button>
    </div>
  );
};

export default EstimateOptions;
